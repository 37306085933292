import React, { useState } from "react";
import { FormattedMessage, Link } from "gatsby-plugin-intl";

import Layout from "../../components/layout";
import Breadcrumbs from "../../components/breadcrumbs";
import useCreateNewUserStore from "../../components/NewUserWizard/steps/useNewUserStore";


const GenerateInvitationLinkPage: React.FC<{ location: any }> = ({ location }) => {
  const { firstName, lastName, magicLink } = useCreateNewUserStore();

  const [copiedMagicLinkToClipboard, setCopiedMagicLinkToClipboard] = useState(false);

  const copyMagicLinkToClipboard = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (magicLink) {
      navigator.clipboard.writeText(magicLink);
      e.target.focus();
      setCopiedMagicLinkToClipboard(true);
    }
  };

  return (
    <Layout>
      <Breadcrumbs
        parentPageUrl="/user-management"
        parentPageTitle={
          <FormattedMessage
            id="user-management"
            defaultMessage="User Management"
          />
        }
        currentPageTitle={
          <FormattedMessage
            id="new_user.copy_link"
            defaultMessage="Copy Invitation Link"
          />
        }
      ></Breadcrumbs>
      <h1 className="block-title">
        <FormattedMessage
          id="new_user.copy_link"
          defaultMessage="Copy Invitation Link"
        />
      </h1>
      <div>
        <p className="fw-bold text-primary">
          User {firstName}&nbsp;
          {lastName}&nbsp; successfully added
        </p>

        <p>Copy Magic Link</p>
        <div className="d-flex mb-3">
          <div className="border rounded p-2 bg-light-subtle">
            {magicLink}
          </div>
          <button className="btn btn-secondary ms-3" onClick={copyMagicLinkToClipboard}>
            {copiedMagicLinkToClipboard ? "Copied" : "Copy"}
          </button>
        </div>

        <Link className="btn btn-primary" to={"/user-management"}>
          Back to all users
        </Link>
      </div>
    </Layout>
  );
};

export default GenerateInvitationLinkPage;
